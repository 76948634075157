html, body {
  margin: 0;
  padding: 0;
}

* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #444;
}

a {
  text-decoration: none;
}

.header {
  width: 100vw;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fff;
}

.header-text {
  font-size: 24px;
  font-weight: bold;
}

.content {
  padding: 72px 8px 0 8px;
}

.card {
  border-radius: 16px;
  border: 1px solid #f0f0f0;
  padding: 16px 24px;
  margin: 16px 0;
}

.card.clicked {
  background-color: #f3f3f3;
  color: #666;
}

.answers {
  text-align: center;
  margin-top: 24px;
  font-size: 22px;
  inline-size: 1.5;
}

.answer {
  color: #f11;
  font-weight: bold;
}